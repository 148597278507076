    .syllabi_detail_box {
        padding-left: 2em;
        width: 65%;
        display: flex;
        flex-direction: column;
        height: 100%
    }

    .syllabi_detail_title {
        font-size: 2vw;
        margin: 0.5em 0;
        font-weight: bold;
    }

    .syllabi_detail_desc {
        font-size: 0.85vw;
        width: 70%;
    }

    .syllabi_container_style {
        margin-left: 0.8em;
        width: 100%;
        height: 80vh !important;
        overflow-y: scroll;
        padding: 0 1.5rem;
        padding-bottom: 3rem;
    }

    .syllabi_description_style {
        flex-grow: 1;
        width: 100%;
        word-wrap: wrap;
        overflow-y: auto;
        padding-bottom: 3rem;
    }

    .lesson_title {
        font-size: 1.6vw; 
        margin-bottom: 0.9vw; 
        font-weight: bold;
    }

    .syllabi_video_container {
        width: 100%;
    }

    .syllabi_teacher_note_box {
        width: 100%; 
    }

    .sub_container_style {
        width: 100%;
    }

    .sub_title {
        font-weight: 600;
        margin: 0.6em 0;
        font-size: 1.5em;
    }

    .note_style {
        margin: 0;
        width: 100%;
        height: 100%;
        padding-right: 15px;
        padding-bottom: 170px;
    }

    .Teachers__Notes__Container p {
        padding: 0.2rem 0;
    }

@media screen and (max-width: 768px) {
    .syllabi_detail_box {
        width: 100%;
    }

    .syllabi_detail_title {
        font-size: 3vw;
    }

    .syllabi_detail_desc {
        font-size: 1.5vw;
        width: 100%;
    }

    .syllabi_container_style {
        width: 85%;
        height: 55vh !important;
        padding: 1em;
        margin-right: 5rem;
    }

    .syllabi_description_style {
        padding-bottom: 0;
    }

    .lesson_title {
        font-size: 3.5vw; 
        margin-bottom: 1.5vw; 
    }

    .teacher_note_box {
        width: 50%; 
    }

    .sub_container_style {
        width: 80%;
        margin-left: 5rem;
    }

    .sub_title {
        font-size: 3vw;
    }

    .note_style {
        padding-bottom: 0;
    }

    .syllabi_detail_teachers_notes_container p {
        padding: 0;
        text-align: justify;
    }

    .syllabi_mobile_view_options {
        display: none;
    }
}

@media screen and (max-width: 425px) {
    .syllabi_detail_box {
        width: 100%;
        padding-left: 0;
    }

    .syllabi_detail_title {
        font-size: 3vw;
    }

    .syllabi_detail_desc {
        font-size: 1.5vw;
        width: 100%;
    }

    .syllabi_container_style {
        width: 100%;
        height: 68vh !important;
        padding: 1em;
        /* display: none; */
    }

    .syllabi_mobile_view_options {
        display: block;
    }

    .syllabi_description_style {
        padding-bottom: 0;
    }

    .lesson_title {
        font-size: 3.5vw; 
        margin-bottom: 1.5vw; 
    }

    .teacher_note_box {
        width: 100%; 
    }

    .sub_container_style {
        width: 100%;
    }

    .sub_title {
        font-size: 3vw;
    }

    .note_style {
        padding-bottom: 0;
    }
}