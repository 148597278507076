.audio_wrapper {
  --border: #C7C7C7;
  --bar-bg: #D9D9D9;
  --played-bar: #3166B2;
  --knobby: #E3E3E3;
  --selectedKnobby: #FFFFFF;
  --played-width: 0;

  width: 100%;
  margin: 20px 0;
}

.borderStyle {
    border: 2px solid var(--border);
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 50px;
    padding: 10px 20px;
}

.audio_timeBox {
  display: flex;
  min-width: 103px;
}

.audio_time {
  font-size: 1.1em;
}
  
.playPause {
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    padding-right: 1.2em;
}
  
.play_pause_icons {
  transform: scale(1.3);
}

.audio_progressBar_box {
  background-color: var(--bar-bg);
  position: relative;
  border-radius: 15px;
  width: 68%;
  height: 5px;
  margin: 1em;
}

.audio_progressBar {
  appearance: none;
  outline: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  border-radius: 10px;
  background: none;
}

.audio_progressBar::-webkit-slider-runnable-track {
  appearance: none;
  outline: none;
  position: absolute;
  left: 0;
  width: 100%;
  margin: 0;
  border-radius: 10px;
  background: none;
}

.audio_progressBar::-moz-range-track {
  appearance: none;
  outline: none;
  position: absolute;
  left: 0;
  width: 100%;
  margin: 0;
  border-radius: 10px;
  background: none;
}

.audio_progressBar::-moz-focus-outer {
  border: 0;
}

.audio_progressBar::before {
  content: '';
  height: 100%;
  width: var(--played-width);
  background: var(--played-bar);
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  cursor: pointer;
}

.audio_progressBar::-moz-range-progress {
  background-color: var(--played-bar);
  border-radius: 10px;
  height: 100%;
  z-index: 2;
}

.audio_progressBar::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: none;
  background-color: var(--knobby);
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  z-index: 3;
}

.audio_progressBar:active::-webkit-slider-thumb {
  transform: scale(1.2);
  background: var(--selectedKnobby);
}

.audio_progressBar::-moz-range-thumb {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: var(--knobby);
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  z-index: 3;
}

.audio_progressBar:active::-moz-range-thumb {
  transform: scale(1.2);
  background: var(--selectedKnobby);
}

.audio_download_icon {
  transform: scale(1.3);
}