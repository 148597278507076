
.select_list_title_style {
    width: 100%;
    padding-left: 0.6em;
    font-size: 1.3em; 
    margin-top: 1em; 
    margin-bottom: 0.4em;
    font-weight: bold;
}

.select_list_hr_line {
    border-top: 1px solid #DEDEDE;
    margin: 10px auto;
    width: 90%;
}

.select_list_tab_container {
    width: 20%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
}

.select_list_tab {
    margin-top: 1.3em;
}

.select_list_children {
    margin-top: auto;
}

@media screen and (max-width: 768px) {
    .select_list_tab_container {
        display: flex;
        width: 100%;
        padding: 0.5em;
        height: 40%;
        flex-direction: column;
    }

    .select_list_title_style {
        width: 100vw;
        font-size: 2em; 
        /* margin-top: 3em;  */
        margin-bottom: -2em;
        font-weight: bold;
        text-align: center;
        padding-right: 1em;
        display: flex;
        justify-content: center;
    }

    .select_list_tab {
        text-align: center;
        font-size: 100px;
        font-weight: bold;
    }

    .select_list_hr_line {
        width: 100%;
        border-top: 1px solid #DEDEDE;
        margin: 10px auto;
    }

    .select_list_children {
        margin-top: 50px;
    }

}

@media screen and (max-width: 425px) {
    .select_list_tab_container {
        display: flex;
        width: 100%;
        padding: 0.5em;
        height: 40%;
        flex-direction: column;
    }

    .select_list_title_style {
        width: 100vw;
        font-size: 1.5em; 
        margin-bottom: -2em;
        font-weight: bold;
        text-align: center;
        padding-right: 1em;
        display: flex;
        justify-content: center;
    }

    .select_list_tab {
        text-align: center;
        font-size: 100px;
        font-weight: bold;
    }

    .select_list_hr_line {
        width: 100%;
        border-top: 1px solid #DEDEDE;
        margin: 10px auto;
    }

    .select_list_children {
        margin-top: 50px;
    }

}
