
    .small_card_container {
        display: flex;
        width: 95%;
        margin-left: 0.6em;
        margin-bottom: 0.6em;
        border: 1px solid #dedede; 
        border-radius: 10px;        
        cursor: pointer;
    }

    .small_card_img_box {
        position: relative;
        width: 20%;
        margin-left: -0.1em;
        padding-top: 20%;
        border-radius: 10px;
    }

    .small_card_img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
    }

    .small_card_title_style {
        width: 100%;
        height: 50%;
        padding-left: 0.5em;
        padding-top: 0.5em;
        font-weight: bold;
        font-size: 0.8vw;
    }

    .small_card_genre_level_style {
        width: 100%;
        height: 50%;
        margin: 0;
        display: flex;
        align-items: center;
    }

    .small_card_genre_level_box {
        display: flex;
        width: 10vw;
    }

    .small_card_icon_box {
        height: 100%;
        margin: auto 0;
    }

    .small_card_icon_style {
        width: 0.9vw;
        vertical-align: middle; 
        margin: 0 7px;
        fill: #375FC5;
    }

    .small_card_icon_text {
        font-size: 0.7vw;
        margin: auto 0;
    }

    .small_card_arrow_container {
        position: relative;
        margin-top: 0.8em;
        margin-right: 0.5em;
    }

    .small_card_arrow_style {
        height: 100%;
    }

    .small_card_genre_card_text {
        padding-top: 0.5em;
        margin: 0;
        font-weight: bold;
        padding-left: 0.5em;
        font-size: 0.85vw;
    }

    .small_card_genre_card_availability {
        width: 100%;
        height: 50%;
        font-weight: bold;
        padding-left: 1em;
        padding-top: 0.5em;
        margin: 0;
        font-size: 0.9vw;
        display: flex;
    }

@media screen and (max-width: 768px) {
    .small_card_container {
        display: flex;
        width: 95%;
        margin-left: 0.6em;
        margin-bottom: 0.6em;
        border: 1px solid #dedede;
        border-radius: 10px;        
        cursor: pointer;
    }

    .small_card_img_box {
        position: relative;
        width: 20%;
        margin-left: -0.1em;
        padding-top: 20%;
        border-radius: 10px;
    }

    .small_card_img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
    }

    .small_card_title_style {
        padding-left: 0.5em;
        padding-top: 0.5em;
        font-weight: bold;
        font-size: 2vw;
    }

    .small_card_genre_level_style {
        width: 100%;
        height: 50%;
        margin: 0;
        display: flex;
        align-items: center;
    }

    .small_card_genre_level_box {
        display: flex;
        width: 10vw;
    }

    .small_card_icon_box {
        height: 90%;
        margin: auto 0;
    }

    .small_card_icon_style {
        width: 0.9vw;
        vertical-align: middle; 
        margin: 0 7px;
    }

    .small_card_icon_text {
        font-size: 1.3vw;
        margin: auto 0;
    }

    .small_card_arrow_container {
        position:relative;
        margin-top: 0.7em;
        margin-right: 0.5em;
    }

    .small_card_arrow_style {
        height: 100%;
    }

    .small_card_genre_card_text {
        font-weight: bold;
        font-size: 1.6vw;
    }

    .small_card_genre_card_availability {
        width: 100%;
        height: 50%;
        font-weight: bold;
        padding-left: 1em;
        padding-top: 0.5em;
        margin: 0;
        font-size: 1.5vw;
        display: flex;
    }
}

@media screen and (max-width: 425px) {
    .small_card_container {
        display: flex;
        width: 95%;
        margin-left: 0.6em;
        margin-bottom: 0.6em;
        border: 1px solid #dedede;
        border-radius: 10px;        
        cursor: pointer;
    }

    .small_card_img_box {
        position: relative;
        width: 30%;
        margin-left: -0.1em;
        padding-top: 20%;
        border-radius: 10px;
    }

    .small_card_img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
    }

    .small_card_title_style {
        width: 100%;
        height: 50%;
        padding-left: 0.5em;
        padding-top: 0.2em;
        font-weight: bold;
        font-size: 2.8vw;
    }

    .small_card_genre_level_style {
        width: 100%;
        height: 50%;
        margin: 0;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        flex-direction: row;
    }

    .small_card_genre_level_box {
        display: flex;
        width: 10vw;
    }

    .small_card_icon_box {
        height: 100%;
        margin-left: auto 0;
    }

    .small_card_icon_style {
        width: 1vw;
        vertical-align: middle; 
        margin: 0 7px;
    }

    .small_card_icon_text {
        font-size: 1.5vw;
        margin: auto 0;
        font-weight: bold;
    }

    .small_card_arrow_container {
        position: relative;
        margin-top: 0.7em;
        margin-right: 0.5em;
    }

    .small_card_arrow_style {
        height: 100%;
    }

    .small_card_genre_card_text {
        margin: 0;
        font-size: 2.85vw;
    }

    .small_card_genre_card_availability {
        width: 100%;
        height: 50%;
        font-weight: bold;
        padding-left: 0.5em;
        padding-top: 0.5em;
        margin: 0;
        font-size: 2.85vw;
        display: flex;
    }
}