
.name_email_box {
    width: 100%;
    height: 100%;
    text-align: center;
    margin-top: 5em;
}

.my_account_container {
    position: static;
}

.icon_container {
    position: absolute;
    top: 30%;
    left: 50%;
    -webkit-transform: translate(-50%,-41%);
    transform: translate(-50%,-41%);
}

.img_underline {
    width: 100%;
    height: 1.6rem;
    background: #375FC5;
    margin: -5px 0;
}

.name_email_title {
    display: block;
    color: #375FC5;
    font-weight: bold;
    margin-top: 10px;
}

.name_email_input {
    border: 2px dashed #E3E3E3;
    margin-top: 10px;
    margin-bottom: 40px;
    width: 350px;
    height: 40px;
    text-align: center;
    font-size: 1.5em;
    font-weight:  bold;
}

.non_edit_icon {
    position: absolute;
    top: 0;
    right: -12px;
    border: 2px solid #375FC5;
    border-radius: 50%;
    padding: 0.1em;
    color: #375FC5;
    cursor: pointer;
    z-index: 1;
    background: #fff;
    font-size: 2em;
}

.icon_img {
    position: relative;
    width: 130px;
    height: 130px ;
    background: #375FC5; 
    border-radius: 50%;
    border: 4px solid #375FC5;
    margin: auto auto;
}

.avatar_style {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 50%;
}

.img_view_my_account {
    width: 100%;
    height: 15em;
}

.logout_button_mobile_view {
    display: none;
}

@media screen and (max-width: 768px) {
    .name_email_box {
        width: 100%; 
        height: 100%;
        text-align: center; 
        display: flex;
        position: relative;
        margin: auto;
        align-items: center;
        flex-direction: column;
        margin-top: 15em;
    }

    .name_email_title {
        font-size: 1.5em;
    }

    .name_email_input {
        border: 2px dashed #E3E3E3;
        margin-top: 10px;
        margin-bottom: 30px;
        width: 250px;
        height: 30px;
        text-align: center;
        font-size: 1em;
        font-weight:  bold;
    }

    .img_view_my_account {
      display: none;
    }
    
    .img_underline {
        display: none;
    }

    .icon_container {
        position: absolute;
        top: 24%;
        left: 50%;
        -webkit-transform: translate(-50%,-41%);
        transform: translate(-50%,-41%);
        width: 10em;
    }

    .non_edit_icon {
        position: absolute;
        top: 0;
        right: 2px;
        border: 2px solid #375FC5;
        border-radius: 50%;
        padding: 0.1em;
        font-size: 2em;
        color: #375FC5;
        cursor: pointer;
        z-index: 1;
        background-color: #fff;
    }

    .logout_button_mobile_view {
        display: block;
        position: absolute;
        bottom: 30%;
        padding: 0.1em;
        font-size: 5em;
        cursor: pointer;
        right: 40%;
    }

    .my_account_container {
        display: block;
        height: 100vh;
    }

}

@media screen and (max-width: 425px) {
    .name_email_box {
        width: 100%; 
        height: 100%;
        text-align: center; 
        display: flex;
        position: relative;
        margin: auto;
        align-items: center;
        flex-direction: column;
        margin-top: 10em;
    }

    .name_email_title {
        font-size: 1.5em;
        margin-top: 1em;
    }

    .name_email_input {
        border: 2px dashed #E3E3E3;
        margin-top: 10px;
        margin-bottom: 30px;
        width: 250px;
        height: 30px;
        text-align: center;
        font-size: 1em;
        font-weight:  bold;
    }

    .img_view_my_account {
      display: none;
    }
    
    .img_underline {
        display: none;
    }

    .icon_container {
        position: absolute;
        top: 13%;
        left: 50%;
        -webkit-transform: translate(-50%,-41%);
        transform: translate(-50%,-41%);
        width: 10em;
    }

    .non_edit_icon {
        position: absolute;
        top: 0;
        right: 2px;
        border: 2px solid #375FC5;
        border-radius: 50%;
        padding: 0.1em;
        font-size: 2em;
        color: #375FC5;
        cursor: pointer;
        z-index: 1;
        background-color: #fff;
    }

    .logout_button_mobile_view {
        display: block;
        position: absolute;
        bottom: 35%;
        padding: 0.1em;
        font-size: 5em;
        cursor: pointer;
        right: 36%;
    }

    .my_account_container {
        display: block;
        height: 100vh;
    }

}
