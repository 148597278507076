
.syllabi_card_container {
    position: relative;
    display: flex;
    height: 5.5vw;
    width: 94%;
    margin: 0.5vw;
    border: 1px solid #dedede;
    border-radius: 10px;
    cursor: pointer;
}

.genre_card_container {
    position: relative;
    display: flex;
    height: 5.5vw;
    width: 83%;
    margin: 0.5vw;
    border: 1px solid #dedede;
    border-radius: 10px;
    cursor: pointer;
}


.img_box {
    position: relative;
    width: 8vw;
    margin-left: 0.5em;
    border-radius: 10px;
    background: lightblue;
}

.syllabi_image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    border-radius: 8px;
}

.syllabi_title {
    position: relative;
    width: 100%;
    font-weight: bold;
}

.syllabi_title_style {
    position: absolute;
    left: 4%;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1vw;
}

.genre_detail_box {
    position: relative;
    width: 13.5vw;
}

.genre_title {
    display: flex;
    height: 100%;
    font-size: 0.9vw;
    padding-left: 0.4vw;
    font-weight: bold;
    justify-content: flex-start;
    align-items: center;
}

.icon_box {
    display: flex;
}

.icon_style {
    width: 0.9vw;
    vertical-align: middle;
    margin: 0 0.4em;
}

.icon_title {
    font-size: 0.85vw;
    margin: auto 0;
}

.arrow_container {
    height: 100%;
    width: 1.3vw;
}

.arrow_icon {
    margin: 1.6vw 0;
    font-size: 1.3vw;
}

@media screen and (max-width: 768px) {
    .syllabi_card_container {
        position: relative;
        display: flex;
        height: 8.5vw;
        width: 95%;
        margin: 0.5vw;
        border: 1px solid #dedede;
        border-radius: 10px;
        cursor: pointer;
    }
    
    .genre_card_container {
        position: relative;
        display: flex;
        height: 8.5vw;
        width: 80%;
        margin: 0.5vw;
        border: 1px solid #dedede;
        border-radius: 10px;
        cursor: pointer;        
    }
    
    .img_box {
        position: relative;
        width: 8vw;
        padding-top: 5%;
        margin-left: 0.5em;
        border-radius: 10px;
        background: lightblue;
    }
    
    .syllabi_image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        aspect-ratio: 1 / 1;
        object-fit: cover;
        border-radius: 8px;
    }
    
    .syllabi_title {
        position: relative;
        width: 100%;
        font-weight: bold;
    }
    
    .syllabi_title_style {
        position: absolute;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        font-size: 1.3vw;
    }
    
    .genre_detail_box {
        position: relative;
        width: 25vw;
    }
    
    .genre_title {
        display: flex;
        height: 100%;
        font-size: 1.5vw;
        padding-left: 0.4vw;
        font-weight: bold;
        justify-content: flex-start;
        align-items: center;
    }
    
    .icon_box {
        display: flex;
    }
    
    .icon_style {
        width: 0.9vw;
        vertical-align: middle;
        margin: 0 0.4em;
    }
    
    .icon_title {
        font-size: 0.85vw;
        margin: auto 0;
    }
    
    .arrow_container {
        height: 100%;
        width: 1.3vw;
    }
    
    .arrow_icon {
        margin: 1.6vw 0;
        font-size: 2vw;
    }
}

@media screen and (max-width: 425px) {
    .syllabi_card_container {
        position: relative;
        display: flex;
        height: 17vw;
        width: 95%;
        margin: 1vw;
        border: 1px solid #dedede;
        border-radius: 10px;
        cursor: pointer;
    }

    .syllabi_title {
        position: relative;
        width: 100%;
        font-weight: bold;
    }

    .syllabi_title_style {
        position: absolute;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        font-size: 3.7vw;
    }

    .genre_card_container {
        position: relative;
        display: flex;
        height: 17vw;
        width: 95%;
        margin: 1vw;
        border: 1px solid #dedede;
        border-radius: 10px;
        cursor: pointer;
    }

    .img_box {
        position: relative;
        width: 25vw;
        padding-top: 5%;
        margin-left: 0.5em;
        border-radius: 10px;
        background: lightblue;
    }

    .genre_title {
        display: flex;
        height: 100%;
        font-size: 3vw;
        padding-left: 1.3vw;
        font-weight: bold;
        justify-content: flex-start;
        align-items: center;
    }

    .arrow_container {
        height: 80%;
        width: 1.3vw;
    }
    
    .arrow_icon {
        margin: 1.6vw 0;
        font-size: 3vw;
    }

    .genre_detail_box {
        position: relative;
        width: 55vw;
    }
}