.studio_dashboard_container {
    position: relative;
    display: flex;
    overflow: hidden;
}

.studio_container_style {
    position: relative;
    float: left;
    width: 75%;
    height: 100vh;
    padding: 25px 25px 0 17px;
}

@media screen and (max-width: 768px) {
    .studio_container_style {
        width: 100%;
        padding: 4px;
    }

    .studio_dashboard_container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }
}

@media screen and (max-width: 425px) {
    .studio_container_style {
        width: 100%;
        padding: 3px;
    }

    .studio_dashboard_container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }
}