.account_container_style {
    position: relative;
    float: left;
    width: 75%;
    height: 100vh;
    padding: 25px 25px 0 17px;
}

.select_list {
    width: 20%;
}

.account_container {
    position: relative;
    display: flex;
    overflow: hidden;
}

@media screen and (max-width: 768px) {
    .select_list {
        display: none; /*Show drawer instead*/
    }

    .account_container_style {
        position: relative;
        width: 100%;
        padding: 25px;
    }
}

@media screen and (max-width: 425px) {
    .select_list {
        display: none; /*Show drawer instead*/
    }

    .account_container_style {
        position: relative;
        width: 100%;
        overflow-y: scroll;
        padding: 25px 25px 0 25px;
    }
}

   