.period_container {
    display: flex;
}

.period_card {
    width: 11vw;
    border: 1px solid #DEDEDE;
    cursor: pointer;
    margin: 0 0.5vw;
    border-radius: 10px;
    padding: 1.25vw 0.3vw;
    text-align: center;
    transition: 0.3s;
}

.period_card:hover {
    background: #DEDEDE;
}

.period_text {
    color: #2066B2;
    font-size: 1.2vw;
    font-weight: bold;
}

.period_desc {
    font-size: 1vw;
}

#stripe_card_element {
    padding-top: 0.4em;
    padding-left: 0.4em;
    height: 2em;
    width: 100%;
    border-radius: 10px;
    background: #F0F0F0;
}

.disabled {
    pointer-events: none;
    opacity: 0.7;
}

@media screen and (max-width: 768px) {
    .period_card {
        width: 30vw;
        margin: 0 0.5vw;
        padding: 1.25vw 0.3vw;
    }

    .period_text {
        font-size: 2.5vw;
    }

    .period_desc {
        font-size: 2vw;
    }
}

@media screen and (max-width: 425px) {
    .period_card {
        width: 27vw;
    }

    .period_text {
        font-size: 2.5vw;
    }

    .period_desc {
        font-size: 2vw;
    }
}