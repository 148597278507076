.dashboard_container_style {
    position: relative;
    display: flex;
    overflow: hidden;
}

.sidebar_container {
    position: relative;
    float: left;
    background: #E6E9FF;
    text-align: center;
}

.dashboard_drawer_mobile {
    display: none;
}

.sidebar_atod_logo {
    width: 3.1em;
    height: 2.4em;
    margin: 1.5em 0;
}

.sidebar_icons  {
    display: block;
    width: 85%;
    height: 2em;
    cursor: pointer;
    margin: 4em auto;
    padding: 0 0.8em;
    color: #E6E9FF;
    opacity: 0.5;
    transition: 0.3s;
}


.sidebar_icons:hover {
    transform: scale(1.05);
    opacity: 1;
}

.sidebar_user_icon {
    position: absolute;
    bottom: 1em;
    height: 3.5em;
    width: 100%;
}

.sidebar_user_avatar {
    margin: 0 auto;
    width: 50px;
    height: 50px;
}

.main_content_mobile_view  {
    display: none;
}

@media screen and (max-width: 768px) {
    .sidebar_container {
        display: flex; /* show the sidebar for smaller screens */
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: auto;
        background: #E6E9FF;
        padding: 1px;
        box-sizing: border-box;
        justify-content: center;
        z-index: 10;
    }

    .dashboard_container_style {
        display: grid;
        grid-template-rows: auto 1fr auto;
        min-height: 100vh;
    }

    .sidebar_icons_group  {
        display: flex;
        justify-content: space-between;
        flex-direction: row; 
        width: 85%;
        margin: -1.5rem;
        margin-top: 3px;

    }

    .sidebar_atod_logo {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
    }

    .sidebar_user_icon {
        margin-left: 1rem;
        bottom: 3rem;
    }
    
    .dashboard_drawer_mobile {
        display: block; 
    }
    
    .sidebar_icons {
        display: block;
        width: 75%;
        height: 2em;
        cursor: pointer;
        margin: 4em auto;
        padding: 0 0.8em;
        color: #E6E9FF;
        opacity: 0.5;
        transition: 0.3s;
    }

}

@media screen and (max-width: 425px) {

    div .main_content_mobile_view  {
        display: block;
    }

    .sidebar_icons_group  {
        display: flex;
        justify-content: space-between;
        flex-direction: row; 
        width: 85%;
        margin: -1.5rem;
        margin-top: 3px;

    }
}
