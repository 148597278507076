.videoControllerBox {
    display: flex;
    position: absolute;
    top: 5%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 22vw;
    gap: 1.5vw;
}

.setting_disabled {
    display: none;
}

.flip_video_wrapper {
    width: 15vw;
    text-align: center;
}

.playbackrate_wrapper {
    width: 15vw;
    text-align: center;
}

.select_wraper {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border-radius: 30px;
    cursor: pointer;
}

.default_option {
    margin: 0.5em 0;
    z-index: 1;
}

.animation {
    animation-name:fadeIn;
    animation-duration:0.5s; 
    animation-fill-mode: forwards;
    z-index: -1;
}

@keyframes fadeIn {
    0% {
        transform: translateY(-15px);
    }
    100% {
        transform: translateY(0);
    }
}

.select_title {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    padding: 5px;
}

.select_title:hover {
    background: #E3E3E3;
}

.flip_video, 
.fast_forward_icon {
    color: #FFFFFF;
    margin-right: 0.4vw;
}

.select_value {
    margin: 0;
    font-size: 1vw;
    width: fit-content;
    color: #FFFFFF;
}

.arrowIcons {
    transform: scale(1.2);
    color: #FFFFFF;
    padding-left: 0.5em;
}