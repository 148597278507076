.boxStyle {
    width: 30%;
    height: 100%;
    margin: 0.55vw;
    border-radius: 10px;
    border: 1px solid #DEDEDE;
    padding-left: 1.3em;
    padding-bottom: 0.5em;
    z-index: 1;
    background: white;
}

.shakeFullSuite {
    position: relative;
}

.shakeFullSuite::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 10px;
    background-size: 300% 300%;
    clip-path: polygon(0% 100%, 3px 100%, 3px 3px, calc(100% - 3px) 3px, calc(100% - 3px) calc(100% - 3px), 3px calc(100% - 3px), 3px 100%, 100% 100%, 100% 0%, 0% 0%);
    animation: frame-enter 1s forwards ease-in-out reverse, gradient-animation 1.5s ease-in-out;
}

@keyframes gradient-animation {
    0% {
      background: linear-gradient(120deg, #46A540, #93A0FF,  #E8323E);
      background-position: 15% 0%;
    }
    50% {
      background: linear-gradient(120deg, #46A540, #93A0FF,  #E8323E);
      background-position: 85% 100%;
    }
    100% {
      background: linear-gradient(120deg, #46A540, #93A0FF,  #E8323E);
      background-position: 15% 0%;
    }
}
  
@keyframes frame-enter {
    0% {
      clip-path: polygon(0% 100%, 3px 100%, 3px 3px, calc(100% - 3px) 3px, calc(100% - 3px) calc(100% - 3px), 3px calc(100% - 3px), 3px 100%, 100% 100%, 100% 0%, 0% 0%);
    }
    25% {
      clip-path: polygon(0% 100%, 3px 100%, 3px 3px, calc(100% - 3px) 3px, calc(100% - 3px) calc(100% - 3px), calc(100% - 3px) calc(100% - 3px), calc(100% - 3px) 100%, 100% 100%, 100% 0%, 0% 0%);
    }
    50% {
      clip-path: polygon(0% 100%, 3px 100%, 3px 3px, calc(100% - 3px) 3px, calc(100% - 3px) 3px, calc(100% - 3px) 3px, calc(100% - 3px) 3px, calc(100% - 3px) 3px, 100% 0%, 0% 0%);
    }
    75% {
      -webkit-clip-path: polygon(0% 100%, 3px 100%, 3px 3px, 3px 3px, 3px 3px, 3px 3px, 3px 3px, 3px 3px, 3px 0%, 0% 0%);
    }
    100% {
      -webkit-clip-path: polygon(0% 100%, 3px 100%, 3px 100%, 3px 100%, 3px 100%, 3px 100%, 3px 100%, 3px 100%, 3px 100%, 0% 100%);
    }
}

.borderColor {
    position: relative;
    z-index: 0;
}

.borderColor::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0%;
    width: 5%;
    height: 100%;
    border-radius: 10px 0 0 10px;
    background: linear-gradient(180deg, #46A540 10%, #93A0FF 30%,  #E8323E 65%, #FFA0D0 100%);
    z-index: -1;
}

.titleStyle {
    padding-top: 0.6vw;
    font-size: 1vw;
    font-weight: bold;
}

.card_desc_box {
    margin: 0.4vw 0;
    padding-right: 0.3em;
}

.card_desc {
    font-size: 0.7vw;
    font-weight: 400;
}

.listStyle {
    font-size: 0.6vw;
}

.cardInfo {
    display: flex;
    width: 100%;
    align-content: center;
    margin-bottom: 0.1vw;
}

.packPrice {
    width: 2.3vw;
    text-align: center;
    font-size: 1.1vw;
    color: #2066B2;
    font-weight: bold;
    padding-left: 0.2vw;
    margin: auto 0;
}

.perMonth {
    width: 100%;
    margin-left: 0.6em;
    padding-top: 0.4vw;
    font-size: 0.7vw;
    color: #8E8E8E;
}

.Genre__Card__Slashed__Price {
    text-decoration: line-through;
    opacity: 0.5;
}


@media only screen and (max-width: 1400px) {
  .cardInfo {
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .boxStyle {
    width: 23%;
    height: 100%;
    margin: 0.55vw;
    border-radius: 10px;
    border: 1px solid #DEDEDE;
    padding-left: 1.3em;
    padding-bottom: 0.5em;
    z-index: 1;
    background: white;
  }

  .titleStyle {
    padding-top: 0.6vw;
    font-size: 1.6vw;
    font-weight: bold;
  }

  .card_desc_box {
    margin: 0.4vw 0;
    padding-right: 0.3em;
  }

  .card_desc {
    font-size: 1vw;
    font-weight: 400;
  }

  .listStyle {
    font-size: 1vw;
  }

  .cardInfo {
    display: flex;
    width: 100%;
    align-content: center;
    margin-bottom: 0.1vw;
  } 

  .Genre__Card__Slashed__Price {
    text-decoration: line-through;
    opacity: 0.5;
  }
  .perMonth {
    width: 100%;
    margin-left: 0.2em;
    padding-top: 0.4vw;
    font-size: 1.2vw;
    color: #8E8E8E;
  }


}

@media screen and (max-width: 425px) {
  .boxStyle {
    width: 85%;
    margin: 0.55vw;
    border-radius: 10px;
    border: 1px solid #DEDEDE;
    padding-left: 1.3em;
    padding-bottom: 0.5em;
    z-index: 1;
    background: white;
    height: 100%;
  }

  .titleStyle {
    padding-top: 0.6vw;
    font-size: 4vw;
    font-weight: bold;
  }

  .card_desc_box {
    margin: 0.4vw 0;
    padding-right: 0.3em;
  }

  .card_desc {
    font-size: 2.8vw;
    font-weight: 400;
  }

  .listStyle {
    font-size: 1.8vw;
  }

  .cardInfo {
    display: flex;
    width: 100%;
    align-content: center;
    justify-content: space-around;
    margin-bottom: 0.1vw;
    gap: 1.3em;
  } 

  .Genre__Card__Slashed__Price {
    text-decoration: line-through;
    opacity: 0.5;
    font-size: 0.9em;
   
  }
  .perMonth {
    width: 100%;
    margin-left: 0.2em;
    padding-top: 0.4vw;
    font-size: 3.2vw;
    color: #8E8E8E;
  }

  .price_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
  }

  .packPrice {
    width: 7vw;
    text-align: center;
    font-size: 3.1vw;
    color: #2066B2;
    font-weight: bold;
    padding-left: 0.2vw;
    margin: auto 0;
  }


}   