
    .studio_genre_packs_wrapper {
        margin-left: 1.2em
    }

    .titleContainer {
        width: 100%;
    }

    .mainTitle {
        font-size: 1.5em;
        font-weight: bold;
    }

    .titleDescription {
        font-size: 14px;
        margin: 5px 0
    }

    .mainContainer {
        position: relative;
        display: flex;
        width: 100%;
        height: 100vh;
        margin-top: 2.5em;
    }

    .genreBox {
        height: 100%;
        float: left;
        width: 65%;
    }

    .studio_genre_packs_plan_box {
        height: 100%;
        float: left;
        width: 25%;
    }

    .confirmBox {
        width: 100%;
        margin: 2em auto;
        margin-bottom: 0;
        background: #D7DFF4;
        text-align: center;
        border-radius: 10px;
    }

    .confirmTitle {
        font-size: 1.4vw;
        color: #2066B2;
        font-weight: bold;
        padding: 0.7em;
    }

    .confirmDesc {
        width: 85%;
        margin: 0 auto
    }

    .textStyle {
        font-size: 0.8vw;
        margin-bottom: 1em;
    }

    .noAccessContainer {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
        z-index: 2;
    }

    .updateContainer {
        width: 17.5em;
        height: 14em;
        position: absolute;
        top: 40%;
        left: 50%;
        -webkit-transform : translate(-50%, -40%);
        transform : translate(-50%, -40%);
        border-radius: 10px;
        background: #F0F0F0;
        text-align: center;
    }

    .arrowIcon {
        transform: rotate(-45deg) scale(1.8, 1.8);
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        margin-top: 1em;
        margin-bottom: 0.5em;
        color: #2066B2;
    }

    .updateTitle {
        color: #2066B2;
        font-weight: bold;
        font-size: 1.3em;
        margin-bottom: 0.5em 0;
    }

    .updateDesc {
        font-size: 0.8em;
        margin-bottom: 0.5em 0
    };

    .studio_genre_packs_plan_box_title_style {
        font-size: 1.6vw;
        margin-top: 50px 20px;
    }

@media screen and (max-width: 768px) {
    .studio_genre_packs_wrapper {
        margin-left: 1em;
        overflow-y: auto;
        height: calc(100% - 300px);
        padding-bottom: 100px;
    }
    
    .mainContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .genreBox {
        width: 100%;
        height: 100%;
    }

    .studio_genre_packs_plan_box {
        width: 95%;
        display: grid;
        margin-top: 8em;
        grid-template-columns: 1fr;
        padding-bottom: 100px;
        height: calc(100% - 200px);
    }

    .confirmBox {
        width: 65%;
        margin: 2em auto;
        margin-bottom: 0;
        background: #D7DFF4;
        text-align: center;
        border-radius: 10px;
    }

    .confirmTitle {
        font-size: 3.4vw;
        color: #2066B2;
        font-weight: bold;
        padding: 0.7em;
    }

    .confirmDesc {
        width: 85%;
        margin: 0 auto
    }

    .textStyle {
        font-size: 1.7vw;
        margin-bottom: 0.5em;
    }

    .noAccessContainer {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
        z-index: 2;
    }

    .updateContainer {
        width: 17.5em;
        height: 14em;
        position: absolute;
        top: 40%;
        left: 50%;
        -webkit-transform : translate(-50%, -40%);
        transform : translate(-50%, -40%);
        border-radius: 10px;
        background: #F0F0F0;
        text-align: center;
    }

    .arrowIcon {
        transform: rotate(-45deg) scale(1.8, 1.8);
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        margin-top: 1em;
        margin-bottom: 0.5em;
        color: #2066B2;
    }

    .updateTitle {
        color: #2066B2;
        font-weight: bold;
        font-size: 1.3em;
        margin-bottom: 0.5em 0;
    }

    .updateDesc {
        font-size: 0.8em;
        margin-bottom: 0.5
    }

    .studio_genre_packs_plan_box_title_style {
        font-size: 2.6vw;
        margin-top: 100px;
    }
} 

@media screen and (max-width: 425px) {
    .studio_genre_packs_wrapper {
        margin-left: 1em;
        height: calc(100vh - 350px);
    }
    
    .mainContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .genreBox {
        width: 100%;
       
    }

    .studio_genre_packs_plan_box {
        width: 90%;
        grid-template-columns: 1fr;
        padding-top: 100vh;
        margin-bottom: 20px;
        height: 50vh;
        
    }

    .confirmBox {
        width: 75%;
        margin: 2em auto;
        background: #D7DFF4;
        text-align: center;
        border-radius: 10px;
        height: 25vh;
        margin-bottom: 1rem;
    }

    .confirmTitle {
        font-size: 3.4vw;
        color: #2066B2;
        font-weight: bold;
        padding: 0.7em;
    }

    .confirmDesc {
        width: 85%;
        margin: 0 auto
    }

    .textStyle {
        font-size: 2.8vw;
        margin-bottom: 0.5em;
    }

    .noAccessContainer {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
        z-index: 2;
    }

    .updateContainer {
        width: 17.5em;
        height: 14em;
        position: absolute;
        top: 40%;
        left: 50%;
        -webkit-transform : translate(-50%, -40%);
        transform : translate(-50%, -40%);
        border-radius: 10px;
        background: #F0F0F0;
        text-align: center;
    }

    .arrowIcon {
        transform: rotate(-45deg) scale(1.8, 1.8);
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        margin-top: 1em;
        margin-bottom: 0.5em;
        color: #2066B2;
    }

    .updateTitle {
        color: #2066B2;
        font-weight: bold;
        font-size: 1.3em;
        margin-bottom: 0.5em 0;
    }

    .updateDesc {
        font-size: 0.8em;
        margin-bottom: 0.5
    }

    .studio_genre_packs_plan_box_title_style {
        font-size: 4vw;
        margin-top: 20px;
    }
}