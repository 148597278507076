.min_width_wrapper {
    position: relative;
    display: flex;
}

.right_container {
    float: left;
    height: 100vh;
    position: relative;
}

.centralizing {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 2vw;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.img_view_login {
    height: 100vh;
    float: left;
    width: 45%;
}

@media screen and (max-width: 768px) {
    .centralizing {
        margin: auto;
        font-size: 12px;
        position: fixed;
        background-color: #D7DFF4;
        align-items: center;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: calc(100% - 2em);
        border-radius: 8px;
        font-size: 15px;
        padding: 1em;
    }

    .min_width_wrapper {
        display: block;
        position: fixed;
    }
    
    .img_view_login {
        display: none;
    }
}

@media screen and (max-width: 425px) {
    .centralizing {
       width: calc(100% - 2em);
       font-size: 10px;
    }
}

.main_title {
    color: #2066B2;
    font-weight: bold;
    font-size: 1.5em;
}

.section_instruction {
    font-size: 0.9em;
    color: #414141;
}

.retrieve_pwd {
    color: #375FC5;
    font-weight: bold;
}

.border_style {
    border-right: 1px solid #E3E3E3; 
    width: 95%;
    margin: 0.9em auto;
}

.trial_box {
    width: 100%;
    height: 80px;
    background: #D7DFF4;
    border-radius: 10px;
    margin: 10px 0;
    text-align: center;
    padding: 10px 0;
}

.section_title {
    color: #2066B2;
    margin-top: 10px;
}

.pdf_container {
    height: 45px;
    background: #F0F0F0;
    border-radius: 10px;
    margin: 10px 0 30px 0;
    padding: 10px 0;
    display: flex;
    cursor: pointer;
}

.pdf_file {
    font-size: 0.9em;
    color: #375FC5;
    margin: auto;
    margin-left: 10px;
    cursor: pointer;
    font-weight: bold;
}

.download_icon {
    padding: 2px;
    margin-right: 10px;
    color: #375FC5;
    height: 100%;
}

.input_email {
    font-size: 14px;
    font-weight: bold;
    color: #414141;
}

.auth_code_input {
    margin: 15px 0;
}