.back_button_container {
    cursor: pointer;
    display: flex;
    width: 30%;
}

.back_button_icon_style {
    display: block;
    margin: auto 0;
    transition: 0.5s;
}

.back_button_text_style {
    display: block;
    margin: auto 0;
    margin-left: 0.5em;
    font-size: 25px;
    transition: 0.5s;
}

@media screen and (max-width: 768px) {
    .back_button_container {
        width: 95%;
        display: flex;
    }

    .back_button_icon_style {
        display: block;
        margin: auto 0;
        transition: 0.5s;
    }

    .back_button_text_style {
        display: block;
        margin: auto 0;
        margin-left: 0.5em;
        font-size: 1.5em;
        transition: 0.5s;
    }
}

@media screen and (max-width: 425px) {
    .back_button_container {
        width: 95%;
        display: flex;
    }

    .back_button_icon_style {
        display: block;
        margin: auto 0;
        transition: 0.5s;
    }

    .back_button_text_style {
        display: block;
        margin: auto 0;
        margin-left: 0.5em;
        font-size: 1.2em;
        transition: 0.5s;
    }
}