.wrapper {
    position: relative;
    padding-top: 56.35%;
}

.player {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 10px;
    overflow: hidden;
}

.rotateY180 {
    transform: rotateY(180deg);
}

.controllerBox {
    /* display: flex; */
    display: none;
    position: absolute;
    bottom: 5%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    height: 2.3em;
    border-radius: 50px;
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);
}

.controllerBox:hover {
    background: #E3E3E3;
}

.videoPlayPause {
    height: 100%;
    font-size: 1.3em;
    margin: 0.3em 0.2em 0 0.5em;
    cursor: pointer;
    color: #FFFFFF;
}

.timeBox {
    display: flex;
    margin-left: 2em;
}

.playTime {
    height: 100%;
    padding: 0.5em 0.3em;
    color: #FFFFFF;
}

.videoProgressBarBox {
    --bar-bg: #535353;
    --played-bar: #3166B2;
    --loaded-bar: #959595;
    --knobby: #E3E3E3;
    --selectedKnobby: #FFFFFF;
    --played-width: 0;
    --loaded-width: 0;

    background-color: var(--bar-bg);
    position: relative;
    border-radius: 15px;
    width: 68%;
    height: 5px;
    margin: 1em;
}

.loadedBar {
    position: absolute;
    top: 0;
    left: 0;
    width: var(--loaded-width);
    background-color: var(--loaded-bar);
    border-radius: 15px;
    height: 100%;
    margin: 0;
}

.videoProgressBar {
    appearance: none;
    outline: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    border-radius: 10px;
    background: none;
}

.videoProgressBar::-webkit-slider-runnable-track {
    appearance: none;
    outline: none;
    position: absolute;
    left: 0;
    width: 100%;
    margin: 0;
    border-radius: 10px;
    background: none;
}

.videoProgressBar::-moz-range-track {
    appearance: none;
    outline: none;
    position: absolute;
    left: 0;
    width: 100%;
    margin: 0;
    border-radius: 10px;
    background: none;
}

.videoProgressBar::-moz-focus-outer {
    border: 0;
}

.videoProgressBar::before {
    content: '';
    height: 100%;
    width: var(--played-width);
    background: var(--played-bar);
    border-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    cursor: pointer;
}

.videoProgressBar::-moz-range-progress {
    background-color: var(--played-bar);
    border-radius: 10px;
    height: 100%;
    z-index: 2;
}

.videoProgressBar::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    border: none;
    background-color: var(--knobby);
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
    z-index: 3;
}

.videoProgressBar:active::-webkit-slider-thumb {
    transform: scale(1.2);
    background: var(--selectedKnobby);
}

.videoProgressBar::-moz-range-thumb {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background-color: var(--knobby);
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
    z-index: 3;
}

.videoProgressBar:active::-moz-range-thumb {
    transform: scale(1.2);
    background: var(--selectedKnobby);
}

.screenfull {
    margin-top: 0.25em;
    margin-right: 0.3em;
    cursor: pointer;
    color: #FFFFFF;
}

.setting {
    width: 1.4em;
    height: 1.4em;
    cursor: pointer;
    color: #FFFFFF;
    text-align: center;
    margin-top: 0.4em;
    margin-right: 1em;
}