.genrePack {
    position: relative;
    display: flex;
    width: 100%;
    padding-left: 0.3vw;
}

.selectedGenrePrice {
    color: #375FC5;
    font-weight: bold;
    font-size: 1.2vw;
    padding-right: 0.3vw;
    margin: auto 0
}

.removeIcon {
    border: none;
    background: #E8323E;
    border-radius: 5px;
    color: #FFFFFF;
    width: 1vw;
    height: 100%;
    cursor: pointer;
    margin: auto 0
}

.editIcon {
    min-width: 0.5em;
    height: 1.2vw;
    border: 2px solid;
    font-weight: bold;
    font-size: 0.55vw;
    cursor: pointer;
    margin: auto 0
}

.genrePrice {
    position: absolute;
    right: 0;
    font-size: 0.8vw
}

.borderStyle {
    border-bottom: 1px solid #E3E3E3;
    width: 90%;
    margin: 1.1vw auto
}

.studio_plan_total_container {
    position: relative;
    display: flex;
    margin-top: 0.9vw;
}

.studio_plan_total_box {
    position: absolute;
    right: 0;
    display: flex;
}

.studio_plan_total_title {
    font-size: 1.1vw;
    font-weight: bold;
}

.studio_plan_total_price {
    font-size: 1.1vw;
    color: #375FC5;
    font-weight: bold;
    margin-right: 0.2vw;
}

.studio_plan_period_title {
    font-size: 0.8vw
}

.studio_plan_per_month {
    font-size: 0.7vw;
    color: #8E8E8E;
    padding-top: 0.4vw;
}

.genre_pack_title_style {
    font-size: 0.8vw;
}

@media screen and (max-width: 768px) {
    .genrePack {
        padding-left: 0.6vw;
    }

    .selectedGenrePrice {
        font-size: 1.6vw;
    }

    .removeIcon {
        width: 2vw;
    }

    .editIcon {
        font-size: 1vw;
    }

    .genrePrice {
        font-size: 1.8vw;
    }

    .borderStyle {
        width: 100%;
    }

    .studio_plan_total_container {
        margin: 1.1vw 0;
    }

    .studio_plan_total_title {
        font-size: 3.6vw;
    }

    .studio_plan_total_price {
        font-size: 2.8vw;
    }

    .studio_plan_period_title {
        font-size: 2.2vw;
    }

    .studio_plan_per_month {
        font-size: 1.8vw;
    }

    .genre_pack_title_style {
        font-size: 2.2vw;
    }
}

@media screen and (max-width: 425px) {
    .genrePack {
        padding-left: 0.6vw;
    }

    .selectedGenrePrice {
        font-size: 1.6vw;
    }

    .removeIcon {
        width: 2vw;
    }

    .editIcon {
        font-size: 1vw;
    }

    .genrePrice {
        font-size: 2.2vw;
    }

    .borderStyle {
        width: 100%;
    }

    .studio_plan_total_container {
        margin-top: 1.5vw;
    }

    .studio_plan_total_title {
        font-size: 3vw;
    }

    .studio_plan_total_price {
        font-size: 3vw;
    }

    .studio_plan_period_title {
        font-size: 3vw;
    }

    .studio_plan_per_month {
        font-size: 2vw;
    }

    .genre_pack_title_style {
        font-size: 3vw;
    }
}