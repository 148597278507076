.syllabi_list_container {
    margin: 0;
    overflow-y: scroll;
    padding-bottom: 200px;
}

.syllabi_list_divider_line {
    width: 45%;
    border-right: 2px solid #DEDEDE;
    margin: 0;
    height: calc(100vh - 100px);
    overflow-y: auto;
    padding-bottom: 100px;

}

@media screen and (max-width: 768px) {
    .syllabi_list_container {
        overflow-y: scroll;
        display: block;
    }

    .syllabi_list_divider_line {
        width: 35%;
        border-right: 2px solid #DEDEDE;
        height: 50vh !important;
        overflow-y: auto;
    }
}

@media screen and (max-width: 425px) {
    .syllabi_list_container {
        display: grid;
        grid-template-columns: 1fr;
        gap: 0.1em;
        padding-top: 20px;
        height: auto;
        margin: 0 auto;
    }

    .syllabi_list_divider_line {
        width: 100%;
        border-right: none;
        height: 70vh !important;
        overflow-y: auto;
    }
}