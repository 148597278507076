    .dashboard_main_content_container_style {
        position: relative;
        float: left;
        width: 75%;
        height: 100vh;
        padding: 25px 25px 0 17px;
    }

    .default_main {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .document_container {
        width: 80%;
        margin: auto;
        height: 100%;
        border: 1px solid rgb(222,222,222);
        border-radius: 15px;
        border-right: none;
    }

    .document_wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
    }

    .doc_download_icon {
        display: none;
    }

    div .main_content_mobile_view  {
        display: none;
    }



@media screen and (max-width: 768px) {
    .dashboard_main_content_container_style {
        height: calc(100% - 1em);
        width: 850px;
        display: flex;
        flex-direction: column;
        padding: 0px;
        margin: auto;
        margin-top: 10px;
        align-items: center;
    }

    .document_container {
        width: 80vw;
        height: calc(100vh - 1em);
        border: 1px solid rgb(222,222,222);
        border-radius: 15px;
        margin: auto;
        margin-right: 3em;
    }

    .document_wrapper {
        width: 80%;
        height: 100%;
        
    }
    
    .default_main {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    div .main_content_mobile_view  {
        display: none;
    } 

}

@media screen and (max-width: 425px) {
    .document_wrapper {
        display: none;
    }

    .main_content_mobile_download_text{
        display: flex;
        flex-direction: column;
        font-size: 4vw;
        text-align: center;
        font-weight: bold;
    }

     div .main_content_mobile_view {
        display: flex;
        flex-direction: column;
        margin: auto;
        font-size: 3.5vw;
        text-align: center;
        font-weight: bold;
    }

    .doc_download_icon {
        display: block;
        cursor: pointer;
        width: 100%;
        height: 2em;
        cursor: pointer;
        opacity: 0.5;
        transition: 0.3s;
        width: 100%;
        margin-top: 20px;
    }

    .dashboard_main_content_container_style {
        height: calc(100vh - 10em);
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0px;
        margin: auto;
        margin-top: 10px;
        align-items: center;
        overflow-y: hidden;
    }
    
     .main_content_mobile_welcome {
        display: block;
        width: 100%;
        margin-bottom: 1em;
    }
    
    .main_content_mobile_view_welcome_title {
        padding: 5px;
    }
}