    
    
@media screen and (min-width: 768px) {
  .icon_avatar {
    display: none;
  }

  .menu_container {
    float: left;
    width: 100%;
    height: 100%;
    padding-right: 1em;
  }

  .close_icon {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0.5rem;
    font-size: 1.5rem;
    color: #375fc5;
    cursor: pointer;
  }
  
  .avatar_text {
    font-size: 12px;
    font-weight: bold;
  }

  .avatar_details_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
    margin-left: 5px;
  }
}

@media screen and (max-width: 425px) {
  .icon_avatar {
    display: none;
  }

  .menu_container {
    float: left;
    width: 100%;
    height: 100%;
    padding-right: 1em;
  }

  .close_icon {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0.5rem;
    font-size: 1.5rem;
    color: #375fc5;
    cursor: pointer;
  }
  
  .avatar_text {
    font-size: 12px;
    font-weight: bold;
  }

  .avatar_details_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
    margin-left: 5px;
  }
}