.genre_packs_borderStyle {
    border-bottom: 1px solid #E3E3E3;
    width: 95%;
    margin: 0;
}

.genre_packs_flexBox {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

@media screen and (max-width: 768px) {
    .genre_packs_flexBox {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 12vh;
    }

    .genre_packs_borderStyle {
        display: none;
    }
}

@media screen and (max-width: 425px) {
    .genre_packs_flexBox {
        display: flex;
        width: 100%;
        height: 15vh;
        gap: 2px
    }

    .genre_packs_borderStyle {
        display: none;
    }
}