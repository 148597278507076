
    .tab_container_style  {
        position: relative;
        display: flex;
        align-items: center;
        height: 3.7vw;
        border-radius: 10px;
        cursor: pointer;
        transition: 0.5s;
    }

    .arrow_style {
        position: absolute;
        right: 1em;
        height: 40%; 
        width: 0.5vw;
        fill: #3166B2
    }

    .title_tab {
        margin-left: 1.2em;
        font-size: 1vw;
    }

@media screen and (max-width: 768px) {
    .tab_container_style {
        position: relative;
        display: flex;
        align-items: center;
        height: 8vw;
        border-radius: 10px;
        cursor: pointer;
        transition: 0.5s;
        justify-content: flex-start;
        /* display: none; */
    }

    .arrow_style {
        position: absolute;
        left: 6em;
        height: 188px; 
        width: 0.5vw;
        fill: #3166B2
        
    }

    .title_tab {
        margin-left: 1.2em;
        font-size: 3vw;
    }
}

@media screen and (max-width: 425px) {
    .tab_container_style {
        position: relative;
        display: flex;
        align-items: center;
        height: 12vw;
        border-radius: 10px;
        cursor: pointer;
        transition: 0.5s;
        justify-content: flex-start;
    }

    .arrow_style {
        position: absolute;
        left: 2.5em;
        height: 188px; 
        width: 0.5vw;
        fill: #3166B2;
    }

    .title_tab {
        margin-left: 1.2em;
        font-size: 3vw;
    }
}