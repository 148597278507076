.subscription_settings_title_style {
    font-size: 1.4em;
    margin-top: 20px;
}

.subscription_settings_desc_style {
    font-size: 0.8em;
    margin-bottom: 10px;
}

.subscription_settings_border_style {
    border-bottom: 1px solid #E3E3E3; 
    width: 80%; 
    margin-top: 20px
}

.subscription_settings_plan_container {
    border-left: 1px solid #E3E3E3; 
    padding: 2.5vw 1.6vw;
}

.subscription_settings_plan_box {
    width: 12vw;
}

.subscription_settings_plan_title {
    font-size: 1.2vw;
}

.subscription_settings_main_container {
    display: flex;
    width: 100%;
    height: 100%;
}

.subscription_settings_block_container {
    display: block;
    width: 500px;
}

.subscription_settings_main_title_style {
    width: 100%;
    font-size: 25px;
}

@media screen and (max-width: 768px) {
    .subscription_settings_title_style {
        font-size: 1.4em;
        margin-top: 20px;
    }
    
    .subscription_settings_desc_style {
        font-size: 0.8em;
        margin-bottom: 10px;
    }
    
    .subscription_settings_border_style {
        border-bottom: 1px solid #E3E3E3; 
        width: 80%; 
        margin-top: 20px
    }
    
    .subscription_settings_plan_container {
        border-left: 1px solid #E3E3E3; 
        padding: 2.5vw;
    }
    
    .subscription_settings_plan_box {
        width: 100%;
    }
    
    .subscription_settings_plan_title {
        font-size: 3vw;
    }
    
    .subscription_settings_main_container {
        display: flex;
        width: 100%;
        height: 100%;
    }
    
    .subscription_settings_block_container {
        display: block;
        width: 500px;
    }
    
    .subscription_settings_main_title_style {
        width: 100%;
        font-size: 25px;
    }
}

@media screen and (max-width: 425px) {
    .subscription_settings_title_style {
        font-size: 1.4em;
        margin-top: 20px;
    }
    
    .subscription_settings_desc_style {
        font-size: 0.8em;
        margin-bottom: 10px;
    }
    
    .subscription_settings_border_style {
        border-bottom: 1px solid #E3E3E3; 
        width: 80%; 
        margin-top: 20px
    }
    
    .subscription_settings_plan_container {
        border-left: 1px solid #E3E3E3; 
        padding: 2.5vw 1.6vw;
        height: calc(100vh - 400px);
    }
    
    .subscription_settings_plan_box {
        width: 100%;
    }
    
    .subscription_settings_plan_title {
        font-size: 4vw;
    }
    
    .subscription_settings_main_container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: auto;
    }
    
    .subscription_settings_block_container {
        display: block;
        width: 700px;
    }
    
    .subscription_settings_main_title_style {
        width: 100%;
        font-size: 25px;
    }
}