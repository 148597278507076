html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}
/* Font imports */

@font-face {
  font-family: "DMSans";
  src: url(/src/assets/Fonts/DMSans-Regular.ttf);
  font-weight: normal;
  font-style: normal;
}
   
@font-face {
  font-family: "DMSans";
  src: url(/src/assets/Fonts/DMSans-Bold.ttf);
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "DMSans";
  src: url(/src/assets/Fonts/DMSans-BoldItalic.ttf);
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "DMSans";
  src: url(/src/assets/Fonts/DMSans-Italic.ttf);
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "DMSans";
  src: url(/src/assets/Fonts/DMSans-Medium.ttf);
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "DMSans";
  src: url(/src/assets/Fonts/DMSans-MediumItalic.ttf);
  font-weight: 500;
  font-style: italic;
}

body {
  position: relative;
  margin: 0;
  font-family: "DMSans", sans-serif;
}

button {
  outline: none;
}

/* Class for spaces in teachers notes from rich text */

/* .Teachers__Notes__Container p {
  padding: 0.6rem 0;
} */

/* .ql-align-justify {
  
} */

button:hover {
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #c2c9d2;
}

p {
  margin: 0;
}

h3 {
  margin: 0;
}

h4 {
  margin: 0;
}

a {
  text-decoration: none;
  color: #1D1D1D;
}

.min_width {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  z-index: 100;
}

@media screen and (max-width: 700px) {
  .min_width {
    display: block;
  }
}

.alert_container {
  position: absolute;
  top: 40%;
  left: 50%;
  -webkit-transform: translate(-50%, -40%);
  transform: translate(-50%, -40%);
  width: 17.5em;
  height: 14em;
  border-radius: 10px;
  background: #F0F0F0;
  text-align: center;
}

.zoom_out_icon {
  transform: scale(1.4);
  margin-top: 1em;
  margin-bottom: 0.5em;
  color: #2066B2;
}

.zoom_out_title {
  color: #2066B2;
  font-weight: bold;
  font-size: 1.3em;
  margin-bottom: 0.5em 0;
}

.zoom_out_desc {
  margin: 0.5em;
  font-size: 0.7em;
}

.Video__Link {
  font-weight: 600;
  color: #000;
  transition: 0.5s;
  text-decoration: underline;
}

.Video__Link:hover {
  cursor: pointer;
  color: darkblue;
}

.tnc_ol {
  padding-left: 1em;
  margin-bottom: 0;
  counter-reset: list;
}

.tnc_ol > li {
  list-style: none;
  position: relative;
}

.tnc_ol > li::before {
  counter-increment: list;
  content: counter(list, lower-alpha) ')';
  position: absolute;
  left: -1.4em;
}

.tnc_ol_second {
  margin-top: 0;
  padding-left: 1em;
}