.container {
    height: 100vh;
    float: left;
    width: 45%;
  }

.imgStyle {
    width:  100%;
    height:  100vh;
    object-fit: cover;
  }

@media (max-width: 767px) {
    .container {
      width: 100%;
    }
    .imgStyle {
      display: none;
      
    }
}