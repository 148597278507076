.description_modal_genre_content_flex_container {
        width: 100%;
        display: block;
        background-color: #e6e9ff;
        height: 100vh;
}

.description_modal_genre_content_title_style {
        font-size: 1em;
        padding-top: 0.4em;
        font-weight: bold;
        padding: 1em;
}

.description_modal_genre_content {
    font-size: 1.3em; 
    display: flex;
    flex-direction: row;
    width: 100%;
}

.description_modal_main_title_genre_desc {
       width: 100%;
       padding: 1.5em;
}

.description_modal_icon {
        width: 1em;
        vertical-align: middle;
        margin-left: 10rem;
        justify-self: right;
        justify-content: right;
        justify-items: right;
}