.payment_method_subsc_setting {
    width: 80%;
    height: 50px;
    border-radius: 10px;
    background: #D7DFF4
}

.payment_method_pack_genre {
    height: 50px;
    border-radius: 10px;
    background: #F0F0F0;
    margin: 10px 0
}

.payment_method_card_title {
    color: #375FC5;
    font-weight: bold;
    padding: 5px 15px;
    height: 50%;
}

.payment_method_card_detail {
    height: 50%;
    padding: 0 15px;
}

.payment_method_cardholder {
    height: 2.3em;
    width: 50%;
    background: #F0F0F0;
    border-radius: 5px;
    margin: 0.5em 0;
    border: none;
    padding: 0 1em;
    font-size: 0.9em;
}

@media screen and (max-width: 768px) {
    .payment_method_subsc_setting {
        width: 80%;
        height: 50px;
        border-radius: 10px;
        background: #D7DFF4
    }
    
    .payment_method_pack_genre {
        height: 50px;
        border-radius: 10px;
        background: #F0F0F0;
        margin: 10px 0
    }
    
    .payment_method_card_title {
        color: #375FC5;
        font-weight: bold;
        padding: 5px 15px;
        height: 50%;
    }
    
    .payment_method_card_detail {
        height: 50%;
        padding: 0 15px;
    }
    
    .payment_method_cardholder {
        height: 2.3em;
        width: 50%;
        background: #F0F0F0;
        border-radius: 5px;
        margin: 0.5em 0;
        border: none;
        padding: 0 1em;
        font-size: 0.9em;
    }
}

@media screen and (max-width: 425px) {
    .payment_method_subsc_setting {
        width: 80%;
        height: 50px;
        border-radius: 10px;
        background: #D7DFF4
    }
    
    .payment_method_pack_genre {
        height: 50px;
        border-radius: 10px;
        background: #F0F0F0;
        margin: 10px 0
    }
    
    .payment_method_card_title {
        color: #375FC5;
        font-weight: bold;
        padding: 5px 15px;
        height: 50%;
    }
    
    .payment_method_card_detail {
        height: 50%;
        padding: 0 15px;
    }
    
    .payment_method_cardholder {
        height: 2.3em;
        width: 50%;
        background: #F0F0F0;
        border-radius: 5px;
        margin: 0.5em 0;
        border: none;
        padding: 0 1em;
        font-size: 0.9em;
    }
}