.syllabi_description_modal_container {
        width: 100%;
        display: block;
        background-color: #e6e9ff;
        height: 100vh;
}

.syllabi_description_modal_title_style {
        font-size: 1em;
        padding-top: 0.4em;
        padding: 1em;
        display: block;
       
}

.syllabi_teachers_notes_container {
    padding: 0;
    text-align: justify;
}

.syllabi_description_modal_icon {
        width: 1em;
        vertical-align: middle;
        margin-left: 18rem;
}