.syllabi_content_container {
    display: flex;
    padding-top: 20px;
}

@media screen and (max-width: 768px) {
    .syllabi_content_container {
        display: flex;
        padding-top: 20px;
        width: 100%;
        height: 95vh;
    }
}

@media screen and (max-width: 425px) {
    .syllabi_content_container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 85vh;
    }

    .syllabi_description_button {
        background-color: #E6E9FF;
        padding: 5px 10px;
        border: 1px solid #2066B2;
        border-radius: 10px;
        font-weight: bold;
        font-size: 0.8em;
        display: block;
        margin: 0 auto; 
        margin-top: 0;
    }

    .syllabi_list_options {
        display: flex;
        width: 100%;
    }
}