    @media only screen and (min-width: 769px){
      .containerSize {
        float: left;
        width: 20%;
      }
    }

    @media screen and (max-width: 768px) {
      .containerSize {
        display: none;
      }
    }
