.main_title_container {
    width: 100%;
    display: inline-block;
}

.main_title_image_box {
    position: relative;
    width: 5vw;
    height: 5vw;
    border-radius: 10px;
    margin: 0.7em;
    background: lightblue;
}

.main_title_image_style {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    border-radius: 10px;
}

.main_title_hr {
    width: 100%;
    border-radius: 10px;
    margin: 0;
}

.main_title_box {
    width: 100%;
    display: flex;
}

.syllabi_main_title_box {
    width: 80%;
    margin-left: 0.6em;
}

.syllabi_main_title_style {
    font-size: 2vw;
    padding-top: 0.4em;
    font-weight: bold;
}

.main_title_genre_level_box {
    margin: 0.5em 0;
    display: flex;
}

.main_title_icon_style {
    width: 1em;
    vertical-align: middle;
    margin: 0 0.3em;
}

.main_title_icon_box {
    width: 5%;
    text-align: center;
}

.main_title_like_style {
    margin: 1.1em 0;
    border-radius: 0.2em;
    cursor: pointer;
    padding: 0.1em;
}

.main_title_info_style {
    margin: 1.1em 0;
    border-radius: 0.2em;
    cursor: pointer;
    width: 2.7vw;
    height: 2.7vw;
}

.main_title_genre_title {
    width: 80%;
    margin-left: 0.5em;
}

.main_title_genre_title_style {
    align-items: center;
    display: flex;
    height: 100%;
    font-size: 2.4vw;
    font-weight: bold;
}

.main_title_availability_box {
    position: relative;
    width: 20%;
}

.main_title_genre_desc {
    width: 60%;
    font-size: 0.7vw;
    margin-bottom: 1.5em;
    margin-left: 1em;
}

.main_title_bottom_hr {
    width: 100%;
    border: 1px solid #DEDEDE;
    margin: 0;
}

.genre_descriptions_header {
    color: #26a4a0;
    font-size: 1.5vw;
}

@media screen and (max-width: 768px) {
    .main_title_container {
        width: 95%;
        overflow-y: scroll;
    }
    
    .main_title_image_box {
        position: relative;
        width: 15vw;
        height: 15vw;
        border-radius: 10px;
        margin: 0.7em;
        background: lightblue;
    }
    
    .main_title_image_style {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        aspect-ratio: 1 / 1;
        object-fit: cover;
        border-radius: 10px;
    }
    
    .main_title_hr {
        width: 100%;
        border-radius: 10px;
        margin: 0;
    }
    
    .main_title_box {
        width: 90%;
        display: flex;
        /* height: 100vh; */
    }
    
    .syllabi_main_title_box {
        width: 80%;
        margin-left: 0.6em;
    }
    
    .syllabi_main_title_style {
        font-size: 2vw;
        padding-top: 0.4em;
        font-weight: bold;
    }
    
    .main_title_genre_level_box {
        margin: 0.5em 0;
        display: flex;
    }
    
    .main_title_icon_style {
        width: 2em;
        vertical-align: middle;
        margin: 0 0.3em;
    }
    
    .main_title_icon_box {
        width: 8%;
        text-align: center;
    }
    
    .main_title_like_style {
        margin: 1.1em 0;
        border-radius: 0.2em;
        cursor: pointer;
        padding: 0.1em;
    }
    
    .main_title_info_style {
        margin: 1.1em 0;
        border-radius: 0.2em;
        cursor: pointer;
    }
    
    .main_title_genre_title {
        width: 70%;
        margin-left: 0.8em;
    }
    
    .main_title_genre_title_style {
        align-items: center;
        display: flex;
        height: 100%;
        font-size: 3vw;
        font-weight: bold;
    }
    
    .main_title_availability_box {
        position: relative;
        width: 20%;
    }
    
    .main_title_genre_desc {
        width: 60%;
        font-size: 1.3vw;
        margin-bottom: 1.5em;
        margin-left: 1em;
    }
    
    .main_title_bottom_hr {
        width: 100%;
        border: 1px solid #DEDEDE;
        margin: 0;
    }
    
    .genre_descriptions_header {
        color: #26a4a0;
        font-size: 1.5vw;
    }
}

@media screen and (max-width: 425px) {
    .main_title_container {
        width: 90%;
        display: none;
    }
    
    .main_title_image_box {
        position: relative;
        width: 15vw;
        height: 10vw;
        border-radius: 10px;
        margin: 0.5em;
        background: lightblue;
    }
    
    .main_title_image_style {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        aspect-ratio: 1 / 1;
        object-fit: cover;
        border-radius: 10px;
    }
    
    .main_title_hr {
        width: 100%;
        border-radius: 10px;
        margin: 0;
    }
    
    .main_title_box {
        width: 100%;
        display: flex;
    }
    
    .syllabi_main_title_box {
        width: 80%;
        margin-left: 0.6em;
    }
    
    .syllabi_main_title_style {
        font-size: 2vw;
        padding-top: 0.4em;
        font-weight: bold;
    }
    
    .main_title_genre_level_box {
        margin: 0.5em 0;
        display: flex;
    }
    
    .main_title_icon_style {
        width: 1em;
        vertical-align: middle;
        margin: 0 0.3em;
    }
    
    .main_title_icon_box {
        width: 8%;
        text-align: center;
    }
    
    .main_title_like_style {
        margin: 1.1em 0;
        border-radius: 0.2em;
        cursor: pointer;
        padding: 0.1em;
    }
    
    .main_title_info_style {
        margin: 1.1em 0;
        border-radius: 0.2em;
        cursor: pointer;
    }
    
    .main_title_genre_title {
        width: 75%;
        margin-left: 0.8em;
    }
    
    .main_title_genre_title_style {
        align-items: center;
        display: flex;
        height: 100%;
        font-size: 5.5vw;
        font-weight: bold;
    }
    
    .main_title_availability_box {
        position: relative;
        width: 20%;
    }
    
    .main_title_genre_desc {
        width: 60%;
        font-size: 4.5vw;
        margin-bottom: 1.5em;
        margin-left: 1em;
    }
    
    .main_title_bottom_hr {
        width: 100%;
        border: 1px solid #DEDEDE;
        margin: 0;
    }
    
    .genre_descriptions_header {
        color: #26a4a0;
        font-size: 3.5vw;
    }
}