    .genre_content_container {
        width: 100%; 
        height: 100vh;
        display: flex;
        flex-direction: column;
    }

    .genre_content_title {
        font-size: 25px; 
        margin: 0 0 5px 10px;
    }

    .genre_content_flex_container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 0.1em;
        padding-top: 20px;
        height: auto;
        margin: 0 auto;
    }

    .genre_content_card_container {
        position: relative;
        overflow-y: auto;
    }

    .no_access_container {
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
        z-index: 2;
    }

    .update_container {
        width: 17.5em;
        height: 14em;
        position: absolute;
        top: 40%;
        left: 50%;
        -webkit-transform : translate(-50%, -40%);
        transform : translate(-50%, -40%);
        border-radius: 10px;
        background: #F0F0F0;
        text-align: center;
    }

    .arrow_icon {
        transform: rotate(-45deg) scale(1.8, 1.8);
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        margin-top: 1em;
        margin-bottom: 0.5em;
        color: #2066B2;
    }

    .update_title {
        color: #2066B2;
        font-weight: bold;
        font-size: 1.3em;
        margin-bottom: 0.5em 0;
    }

    .update_desc {
        font-size: 0.8em;
        margin-bottom: 0.5em 0;
    }

    .available_container {
        z-index: 1;
        /* height: calc(100vh - 30px); */
    }

    .description_button {
        display: none;
    }

    .genre_content_description_button {
        display: none;
    }

@media screen and (max-width: 768px) {
    .genre_content_container {
        width: 100%; 
        height: calc(100vh - 200px);
        display: flex;
        flex-direction: column;
    }

    .genre_content_title {
        font-size: 25px; 
        margin: 0 0 5px 10px;
    }

    .genre_content_flex_container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding-top: 20px;
        height: auto;
        margin: 0 auto;
    }

    .genre_content_card_container {
        position: relative;
        overflow-y: auto;
    }

    .no_access_container {
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
        z-index: 2;
    }

    .update_container {
        width: 17.5em;
        height: 14em;
        position: absolute;
        top: 40%;
        left: 50%;
        -webkit-transform : translate(-50%, -40%);
        transform : translate(-50%, -40%);
        border-radius: 10px;
        background: #F0F0F0;
        text-align: center;
    }

    .arrow_icon {
        transform: rotate(-45deg) scale(1.8, 1.8);
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        margin-top: 1em;
        margin-bottom: 0.5em;
        color: #2066B2;
    }

    .update_title {
        color: #2066B2;
        font-weight: bold;
        font-size: 1.3em;
        margin-bottom: 0.5em 0;
    }

    .update_desc {
        font-size: 0.8em;
        margin-bottom: 0.5em 0;
    }

    .available_container {
        z-index: 1;
    }

    .description_button {
        display: none;
    }
} 

@media screen and (max-width: 425px) {
    .genre_content_flex_container {
        display: grid;
        grid-template-columns: 1fr;
        gap: 0.1em;
        padding-top: 20px;
        height: calc(100% - 2em);
        margin: 0 auto;
    }

    .genre_content_card_container {
        position: relative;
    }

     .available_container {
        z-index: 1;
    }

    .genre_content_description_button {
        background-color: #E6E9FF;
        padding: 5px 10px;
        border: 1px solid #2066B2;
        border-radius: 10px;
        font-weight: bold;
        font-size: 0.5em;
        display: block;
        margin: 0 auto; 
    }

    .genre_content_title {
        font-size: 1.7em; 
        margin: 0 0 25px 40px;
        display: flex;
        flex-direction: row;
    }
}