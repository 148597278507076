
    .my_library_title_container {
        width: 100%;
        height: 10%;
        padding-left: 0.5em;
    }

    .my_library_title_style {
        font-size: 1.3em; 
        margin-top: 1em; 
        margin-bottom: 0.4em;
        font-weight: bold;
    }

    .list_style {
        width: 100%;
        height: 90%; 
        overflow-y: scroll;
        overflow-x: hidden;
        padding-bottom: 1.5em;
        margin-top: 0.5em;
    }

    .no_fav_syllabi {
        width: 100%;
        text-align: center;
        color: #005FC5;
        font-size: 1vw;
    }

    .category_title {
        display: flex;
        margin: 0.8em 0;
        padding-left: 0.5em;
    }

    .my_library_icon_style {
        height: 100%;
        fill: #375FC5;
        text-align: center; 
        align-items:center;
        width: 1.3vw;
        margin: auto 0;
    }

    .my_library_title_font {
        margin: 0 10px;
        font-size: 1.1vw;
    }


@media screen and (max-width: 768px) {
    .my_library_title_container {
        width: 100%;
        height: 10%;
        padding-left: 0.5em;
    }

    .my_library_title_style {
        font-size: 1.3em; 
        margin-top: 1em; 
        margin-bottom: 0.4em;
        font-weight: bold;
    }

    .list_style {
        width: 100%;
        height: 90%; 
        overflow-y: scroll;
        overflow-x: hidden;
        padding-bottom: 1.5em;
        margin-top: 0.5em;
    }

    .no_fav_syllabi {
        width: 100%;
        text-align: center;
        color: #005FC5;
        font-size: 1vw;
    }

    .category_title {
        display: flex;
        margin: 0.8em 0;
        padding-left: 0.5em;
    }

    .my_library_icon_style {
        height: 100%;
        fill: #375FC5;
        text-align: center; 
        align-items: center;
        width: 3vw;
        margin: auto 0;
    }

    .my_library_title_font {
        margin: 0 10px;
        font-size: 2em;
    }
}

@media screen and (min-width: 320px) {
    .my_library_title_container {
        width: 100%;
        height: 10%;
        padding-left: 0.5em;
    }

    .my_library_title_style {
        font-size: 1.3em; 
        margin-top: 1em; 
        margin-bottom: 0.4em;
        font-weight: bold;
    }

    .list_style {
        height: 90%; 
        overflow-y: scroll;
        overflow-x: hidden;
        padding-bottom: 2.5em;
        margin-top: 0.5em;
    }

    .no_fav_syllabi {
        width: 100%;
        text-align: center;
        color: #005FC5;
        font-size: 1vw;
    }

    .category_title {
        display: flex;
        margin: 0.8em 0;
        padding-left: 0.5em;
        margin-top: 10px 0;
    }

    .my_library_icon_style {
        height: 100%;
        fill: #375FC5;
        text-align: center; 
        align-items:center;
        width: 1vw;
        margin: auto 1px;
    }

    .my_library_title_font {
        font-size: 0.90em;
        margin-left: 25px;
    }

}